import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Customer from './components/Customer';
import Payments from './components/Payments';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { createContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import AdminDashboard from './components/AdminDashboard';
import Distributor from './components/Distributor';
import DistributorwiseOverdue from './components/DistributorwiseOverdue';
import DistributorwiseOverdueEMIs from './components/DistributorwiseOverdueEMIs';
import CustomersOverdue from './components/CustomersOverdue';
import EMIsOverdue from './components/EMIsOverdue';
import './custom.scss'
import GreattrLogo from './components/assets/images/logo-DH.png'
import Signin from './components/Signin';
import { LoginContext } from './components/LTC';
import Loggedout from './components/Loggedout';
import { useEffect } from 'react';
function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Load count from sessionStorage when the component mounts
    const savedCount = sessionStorage.getItem('loggedin');
    // alert(savedCount)
    if (savedCount) {
      setIsLoggedIn((savedCount));
    }
  }, []);
  const location = useLocation();
  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <Routes>
        <Route path="/" element={<Signin />} />
      </Routes>
      {isLoggedIn ? <>
        <Offcanvas show={show} onHide={handleClose} className="flex-column bg-lightx sidebar" placement={'end'}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ color: 'white' }}>Greattr Dashboard</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column sidebar">
              <LinkContainer to="/Dashboard">
                <Nav.Link onClick={handleClose}>Dashboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/Customer">
                <Nav.Link onClick={handleClose}>Customers</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/Distributor">
                <Nav.Link onClick={handleClose}>Distributor</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/DistributorwiseOverdue">
                <Nav.Link onClick={handleClose}>Distributor wise Overdue Customers</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/DistributorwiseOverdueEMIs">
                <Nav.Link onClick={handleClose}>Distributor wise Overdue EMIs</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/CustomersOverdue">
                <Nav.Link onClick={handleClose}>Customers Overdue</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/EMIsOverdue">
                <Nav.Link onClick={handleClose}>EMIs Overdue</Nav.Link>
              </LinkContainer>
              <button onClick={() => {
                setIsLoggedIn(false)
                sessionStorage.clear('loggedin');
                navigate("/");
              }}> Logout</button>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>

        <Navbar bg="dark" className='navbg' >
          <Container>
            <Navbar.Brand href="/Dashboard"><img src={GreattrLogo} alt="GreattrLogo" width={120} /></Navbar.Brand>
            <div>
              <FontAwesomeIcon icon={faBars} onClick={handleShow} size="2x" style={{ color: 'white', cursor: 'pointer' }} />
            </div>
          </Container>
        </Navbar>
        <Routes>

          <Route path="/Dashboard" element={<AdminDashboard />} />
          <Route path="/Payments" element={<Payments />} />
          <Route path="/Customer" element={<Customer />} />
          <Route path="/Distributor" element={<Distributor />} />
          <Route path="/DistributorwiseOverdue" element={<DistributorwiseOverdue />} />
          <Route path="/DistributorwiseOverdueEMIs" element={<DistributorwiseOverdueEMIs />} />
          <Route path="/CustomersOverdue" element={<CustomersOverdue />} />
          <Route path="/EMIsOverdue" element={<EMIsOverdue />} />
        </Routes>
      </>
        : location.pathname === '/' ? null : <Loggedout />
      }

      {/* <Payments /> */}</LoginContext.Provider>
  );
}

export default App;
