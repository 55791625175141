import React, { useContext } from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput
}
    from 'mdb-react-ui-kit';
import GreattrLogo from './assets/images/greattr_name_logo.png'
import './Signin.scss'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from './LTC';
function Signin() {
    const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

    const [Username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("Username:", Username);
        // console.log("Password:", password);

        if (Username === "bpclGreattr0410" && password === "jaigurudev") {
            setIsLoggedIn(true);
            sessionStorage.setItem('loggedin', true);
            navigate("/dashboard");
        }
        if (Username === "bpclGreattrAB" && password === "JBB281099") {
            setIsLoggedIn(true);
            sessionStorage.setItem('loggedin', true);
            navigate("/dashboard");
        }
        if (Username === "BPCL@UP" && password === "Lucknow@123") {
            sessionStorage.setItem('loggedin', true);
            setIsLoggedIn(true);
            navigate("/dashboard");
        }
        if (Username === "admin" && password === "8779096439") {
            sessionStorage.setItem("Username", Username);
            sessionStorage.setItem('loggedin', true);
            setIsLoggedIn(true);
            sessionStorage.setItem("password", password);
            navigate("/Dashboard");
        }
    };
    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <img src={GreattrLogo} alt="GreattrLogo" width={'50%'} />
                {/* <label htmlFor="Username">Username:</label> */}
                <br /><br />
                <input
                    type="Username"
                    id="Username"
                    className='inputx'
                    placeholder='Username'
                    value={Username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                {/* <label htmlFor="password">Password:</label> */}
                <input
                    type="password"
                    className='inputx'
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit" className='fancy-button'>Submit</button>
            </form>
        </div>
    );
}

export default Signin;