import React, { useEffect, useRef, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap';
import user_icon from '../assets/user_icon.svg';
import { default as ReactSelect } from "react-select";
import 'react-calendar/dist/Calendar.css';
import { components } from "react-select";
import { Button, Pagination, Table } from 'react-bootstrap';
import search_icon from '../assets/search_icon.svg';
import download_icon from '../assets/download_icon.svg';
import refresh_icon from '../assets/refresh_icon.svg';
import three_dots from '../assets/three_dots.svg';
import './Customer.scss'
import Calendar from 'react-calendar';
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function TableStatus({ text }) {
    const textx = text.toLowerCase()
    // console.log(textx);
    // console.log(textx === 'rejected');
    const color = textx === 'rejected' ? '#EB5757' :
        textx === 'pending' ? '#F2994A' : '#12A474';
    const bgcolor = textx === 'rejected' ? '#FFDBDB' : textx === 'pending' ? '#FFF3DB' : '#BBF3E0';
    return (
        <span style={{ backgroundColor: bgcolor, color: color, fontSize: 12, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8, textTransform: 'capitalize' }}>{text}</span>
    )
}
function TableDeliveryandLDandUJJWALA({ text }) {
    const textx = text.toLowerCase()
    const color = textx === 'no' || textx === 'false' ? '#EB5757' :
        '#12A474';
    const bgcolor = textx === 'no' || textx === 'false' ? '#FFDBDB' : '#BBF3E0';
    return (
        <span style={{ backgroundColor: bgcolor, color: color, fontSize: 12, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8, textTransform: 'capitalize' }}>{textx === 'false' ? 'No' : 'Yes'}</span>
    )
}

const distributorApprovalStatusList = [
    { value: 'approved', label: 'Approved', isSelected: true },
    { value: 'pending', label: 'Pending' },
    { value: 'rejected', label: 'Rejected' },
];
const loanDisbursementStatusList = [
    { value: 'approved', label: 'Approved' },
    { value: 'pending', label: 'Pending' },
    { value: 'rejected', label: 'Rejected' },
];
const deliveryStatusList = [
    { value: 'true', label: 'Delivered' },
    { value: 'false', label: 'Not Delivered' },
];
const ccList = [
    { value: 'lpg_dist_id', label: 'CC Code' },
    { value: 'lpg_dist_name', label: 'Dist Name' },
    { value: 'lpg_id', label: 'LGP ID' },
    { value: 'name', label: 'Cust Name' }
]
const options = { day: 'numeric', month: 'numeric', year: '2-digit' };
const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function Distributor() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const [distributorStatus, setDistributorStatus] = useState(null);
    const [loanDisbursementStatus, setLoanDisbursementStatus] = useState(null);
    const [deliveryStatus, setDeliveryStatus] = useState(null);
    const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
    const [isEndDateVisible, setIsEndDateVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const columns = ["DIST ID", "Dist Name", "Customers approved", "Customers pending", "Customers rejected", "Loan disbursed", "Disbursement pending", "Disbursement rejected"]
    const [data, setdata] = useState(null)

    const onClickSearch = () => {
        fetchData();
    }
    const handlePageChange = (value) => {
        setPage(value);
        console.log(value);
        fetchData()
    };
    const pageCount = Math.ceil(totalCount / pageSize);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            await axios({
                url: `${process.env.REACT_APP_API_LINK}/distinctdistributors?searchedValue=${searchValue}`,
                method: "GET",
                responseType: "json",
            }).then((response) => {
                console.log(response);
                setdata(response.data);
                setIsLoading(false);
                localStorage.setItem("distinctdist", response.data);
            });
        } catch (err) {
            console.log("error fetching ");
            console.log(err);
        }
    };
    const downloaddata = async () => {
        window.open(
            `${process.env.REACT_APP_API_LINK}/downloaddistinctdistributors`,
            "_blank"
        );
    };

    useEffect(() => {
        fetchData()
    }, [])

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }
    const fields = ["lpg_dist_id", "lpg_dist_name", "statusapproved", "statuspending", "statusrejected", "disbursementapproved", "disbursementpending", "disbursementrejected"];
    const getPageItems = () => {
        const items = [];

        // Add Prev button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => {
                    if (page > 1) {
                        handlePageChange(page - 1);
                    }
                }}
                disabled={page <= 1}
            />
        );

        // Add page numbers
        for (let i = Math.max(1, page - 4); i <= Math.min(pageCount, page + 5); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={page === i}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add Next button
        items.push(
            <Pagination.Next
                key="next"
                onClick={() => {
                    if (page < pageCount) {
                        handlePageChange(page + 1);
                    }
                }}
                disabled={page >= pageCount}
            />
        );

        return items;
    };


    return (
        <Container>
            <div className="d-flex justify-content-between"
                style={{ height: 100 }}
            >
                <h1 className='align-self-end'>Distributors</h1>
                {/* <img src={user_icon} alt="" className='m-4' height={44} width={44} /> */}
            </div>
            <div className='d-flex flex-wrap'>
                <div className="input-group m-1" style={{ maxWidth: 600 }}>
                    <img src={search_icon} alt="" height={44} width={44} className="input-group-text bg-light  border light" id="basic-addon1" />
                    <input type="text" className="form-control border light" value={searchValue} onChange={v => setSearchValue(v.target.value)} placeholder="Search" aria-label="search" aria-describedby="basic-addon1" />
                </div>
                {/* <Button variant="light border m-1" onClick={onClickSearch}>Search</Button> */}
                <img src={download_icon} alt="" height={44} width={44}
                    className="input-group-text bg-light border light m-1 my-button" id="basic-addon1"
                    onClick={() => downloaddata()}
                />
                <img src={refresh_icon} alt="" height={44} width={44}
                    className="input-group-text bg-light border light m-1 my-button" id="basic-addon1"
                    onClick={() => fetchData()} />
            </div>
            <div className='d-flex flex-wrap  justify-content-between'>

                <Button variant="primary fw-bold mx-1 my-2 " onClick={onClickSearch}>Search</Button>
            </div>

            {data != null && !isLoading ?
                <Table className='mt-3' hover style={{ textAlign: 'center', alignItems: 'center' }} responsive>
                    <thead>
                        <tr style={{ color: '#809FB8', height: 50, verticalAlign: "middle" }}>
                            {columns.map((column) => <td key={column} onClick={() => {
                                // alert(column);
                                handleSort(column)
                            }}>{column}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => {
                            if (!sortField) return 0;
                            const aValue = a[sortField];
                            const bValue = b[sortField];

                            if (aValue === undefined || bValue === undefined) return 0;
                            const comparison = aValue.localeCompare(bValue);
                            return sortOrder === 'asc' ? comparison : -comparison;
                        }).map((item) => (
                            <tr key={item.distid}>
                                {fields.map((field) => (
                                    <td
                                        key={`${item.distid}`}
                                        style={{
                                            color: "#06152B",
                                            fontWeight: "400",
                                            fontSize: "0.81rem",
                                        }}
                                    >
                                        {item[field]}
                                    </td>
                                ))} </tr>
                        ))}
                    </tbody>
                </Table> :

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Spinner animation="border" /></div>
            }
            <div>
                {/* Math.ceil(totalCount / pageSize) */}
                <Pagination style={{ float: 'right' }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last onClick={() => handlePageChange(pageCount)} />
                </Pagination>
            </div>
        </Container>
    )
}
