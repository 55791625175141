import React from 'react'
import './Loggedout.scss'
function Loggedout() {
    return (
        <div className="logged-out-container">
            <h1>You have been logged out</h1>
            <p>Please Log in again to continue using our services.</p>
            {/* Add your login form or button here */}
        </div>
    )
}

export default Loggedout