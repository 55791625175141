import React, { useState, useEffect, useRef, useContext } from "react";
import totaldisticon from "./assets/people 1.png";
import activetotaldisticon from "./assets/ActiveDist.png";
import totalcust from "./assets/TotalDist.png";
import refresh from "./assets/refresh-svgrepo-com.png";
import approved from "./assets/approved-shield 1.png";
import deliveriesdone from "./assets/truck-svgrepo-com.png";
import rupeeDone from "./assets/rupee-svgrepo-com (1).png";
import rupee from "./assets/rupee-svgrepo-com.png";
import tickdone from "./assets/tick-circle-svgrepo-com.png";
import pending from "./assets/pending 1.png";
import "./AdminConsole.scss";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  let today = new Date();
  const [loading, setloading] = useState(true);
  var TotalLoans;
  var TotalCustomers;
  var LoansApproved;
  var LoansPending;
  var DeliveryDone;
  var LoansPaid;
  var Disbursed;
  var DisbursementPending;
  const [stats, setstats] = useState([]);
  let date =
    today.getDate() +
    "/" +
    parseInt(today.getMonth() + 1) +
    "/" +
    today.getFullYear();
  const fetchdata = async () => {
    try {
      setloading(true);
      await axios({
        url: `${process.env.REACT_APP_API_LINK}/GreattrStats/`,
        method: "GET",
        responseType: "json",
      }).then((response) => {
        console.log("refilldata", response);
        // [[{"Total_loans":978}],[{"Total_Customers":1236}],[{"Loans_approved":837}],[{"Loans_pending":139}]
        // ,[{"Delivery_done":557}],[{"Loan_Paid":0}],[{"Disbursed":0}],[{"DisbursementPending":851}]]
        setstats(response.data);
        setloading(false);
        TotalLoans = response.data[0][0]["Total_loans"];
        TotalCustomers = response.data[1][0]["Total_Customers"];
        console.log(TotalCustomers);
        // LoansApproved = response.data[2][0]["Loans_approved"];
        // LoansPending = response.data[3][0]["Loans_pending"];
        // DeliveryDone = response.data[4][0]["Delivery_done"];
        // LoansPaid = response.data[5][0]["Loan_Paid"];
        // Disbursed = response.data[6][0]["Disbursed"];
        // DisbursementPending = response.data[7][0]["DisbursementPending"];
      });
    } catch (err) {
      console.log("error fetching ");
      console.log(err);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchdata();
  }, []);
  if (!loading) {
    return (
      <>
        <div className="dashboard">
          <div className="dashboardcontent">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h1 className="titlex"> Dashboard</h1>
              <Button onClick={() => fetchdata()}>Refresh</Button>

              {/* <img
                onClick={() => fetchdata()}
                src={refresh}
                alt="refresh" width={250}
                className='image'
              /> */}
            </div>
            <h1 className="date"> {date}</h1>
            <div className="dashboardinner">
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/distributors')
                  // navigate('/customers', {
                  //   state: {
                  //     statusCheckedItems: {
                  //       approved: true,
                  //       pending: false,
                  //       rejected: false,
                  //     },
                  //     DeliveryStatuscheckedItems: {
                  //       true: true,
                  //       false: true,
                  //     },
                  //     LDcheckedItems: {
                  //       approved: true,
                  //       pending: true,
                  //       rejected: true,
                  //     }
                  //   }
                  // });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#fff9e1",
                  }}
                >
                  <img
                    src={totalcust}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Total Distributors</h5>
                  <h2>{stats[2][0]["Total_Distributors"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/distributors')
                  // navigate('/customers', {
                  //   state: {
                  //     statusCheckedItems: {
                  //       approved: true,
                  //       pending: false,
                  //       rejected: false,
                  //     },
                  //     DeliveryStatuscheckedItems: {
                  //       true: true,
                  //       false: true,
                  //     },
                  //     LDcheckedItems: {
                  //       approved: true,
                  //       pending: true,
                  //       rejected: true,
                  //     }
                  //   }
                  // });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={activetotaldisticon}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Total Active Distributors</h5>
                  <h2>{stats[3][0]["Total_Active_Distributors"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: true,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#9fc4f8",
                    backgroundColor: "#9fc4f8",
                  }}
                >
                  <img
                    src={totaldisticon}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Total Loans</h5>
                  <h2>{stats[0][0]["Total_loans"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: true,
                        pending: false,
                        rejected: false,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: true,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={approved}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Approved Loans</h5>
                  <h2>{stats[4][0]["Loans_approved"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: false,
                        pending: true,
                        rejected: false,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: true,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#ffd9e4",
                  }}
                >
                  <img
                    src={pending}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Pending Loans</h5>
                  <h2>{stats[5][0]["Loans_pending"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: false,
                        pending: false,
                        rejected: true,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: true,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#ffd9e4",
                  }}
                >
                  <img
                    src={pending}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Rejected Loans</h5>
                  <h2>{stats[10][0]["Loans_rejected"] + ""}</h2>
                </div>
              </div>

              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: false,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={deliveriesdone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Deliveries done</h5>
                  <h2>{stats[6][0]["Delivery_done"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/Customers-Overdues', {
                    state: {
                      paidstatus: {
                        true: true,
                        false: false,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#9fc4f8",
                    backgroundColor: "#9fc4f8",
                  }}
                >
                  <img
                    src={tickdone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>EMIs Paid</h5>
                  <h2>{stats[7][0]["Loan_Paid"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard"
                onClick={() => {
                  navigate('/customers', {
                    state: {
                      statusCheckedItems: {
                        approved: true,
                        pending: true,
                        rejected: true,
                      },
                      DeliveryStatuscheckedItems: {
                        true: true,
                        false: true,
                      },
                      LDcheckedItems: {
                        approved: true,
                        pending: false,
                        rejected: false,
                      }
                    }
                  });
                }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={rupeeDone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Disbursed</h5>
                  <h2>{stats[8][0]["Disbursed"] + ""}</h2>
                </div>
              </div>
              <div className="Dashboardcard" onClick={() => {
                navigate('/customers', {
                  state: {
                    statusCheckedItems: {
                      approved: true,
                      pending: true,
                      rejected: true,
                    },
                    DeliveryStatuscheckedItems: {
                      true: true,
                      false: true,
                    },
                    LDcheckedItems: {
                      approved: false,
                      pending: true,
                      rejected: false,
                    }
                  }
                });
              }}>
                <div
                  className="left"
                  style={{
                    backgroundColor: "#fff9e1",
                  }}
                >
                  <img
                    src={rupee}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Disbursement Pending</h5>
                  <h2>{stats[9][0]["DisbursementPending"] + ""}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <h3>Loading</h3>
      </>
    );
  }
};

export default AdminDashboard;
