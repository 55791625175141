import React, { useEffect, useRef, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap';
import user_icon from '../assets/user_icon.svg';
import { default as ReactSelect } from "react-select";
import 'react-calendar/dist/Calendar.css';
import { components } from "react-select";
import { Button, Pagination, Table } from 'react-bootstrap';
import search_icon from '../assets/search_icon.svg';
import download_icon from '../assets/download_icon.svg';
import refresh_icon from '../assets/refresh_icon.svg';
import three_dots from '../assets/three_dots.svg';
import './Customer.scss'
import Calendar from 'react-calendar';
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function TableStatus({ text }) {
    const textx = text.toLowerCase()
    // console.log(textx);
    // console.log(textx === 'rejected');
    const color = textx === 'rejected' ? '#EB5757' :
        textx === 'pending' ? '#F2994A' : '#12A474';
    const bgcolor = textx === 'rejected' ? '#FFDBDB' : textx === 'pending' ? '#FFF3DB' : '#BBF3E0';
    return (
        <span style={{ backgroundColor: bgcolor, color: color, fontSize: 12, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8, textTransform: 'capitalize' }}>{text}</span>
    )
}
function TableDeliveryandLDandUJJWALA({ text }) {
    const textx = text.toLowerCase()
    const color = textx === 'no' || textx === 'false' ? '#EB5757' :
        '#12A474';
    const bgcolor = textx === 'no' || textx === 'false' ? '#FFDBDB' : '#BBF3E0';
    return (
        <span style={{ backgroundColor: bgcolor, color: color, fontSize: 12, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8, textTransform: 'capitalize' }}>{textx === 'false' ? 'No' : 'Yes'}</span>
    )
}

const PaidorNot = [
    { value: 'true', label: 'Yes', isSelected: true },
    { value: 'false', label: 'No' },
];

const ccList = [
    { value: 'lpg_dist_id', label: 'CC Code' },
    { value: 'lpg_dist_name', label: 'Dist Name' },
    { value: 'lpg_id', label: 'LGP ID' },
    { value: 'name', label: 'Cust Name' }
]
const options = { day: 'numeric', month: 'numeric', year: '2-digit' };
const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function EMIsOverdue() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [startDate, setStartDate] = useState(new Date('2020-06-30'));
    const [endDate, setEndDate] = useState(new Date());
    const [isPaidorNot, setisPaidorNot] = useState(null);
    const [loanDisbursementStatus, setLoanDisbursementStatus] = useState(null);
    const [deliveryStatus, setDeliveryStatus] = useState(null);
    const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
    const [isStartDateVisible, setIsStartDateVisible] = useState(false);
    const [isEndDateVisible, setIsEndDateVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('All');
    const [totalCount, setTotalCount] = useState(0);
    const columns = [
        'LPG ID', 'Disbursed', 'LPG Mobile Number', 'Customer Name', 'LPG Distributor ID',
        , 'LPG Distributor Name',
        'Due Date', 'Helper ID'
    ];
    const [data, setdata] = useState(null)
    const onStartDateChange = (value) => {
        setStartDate(value);
        setIsStartDateVisible(!isStartDateVisible);
    }
    const onEndDateChange = (value) => {
        setEndDate(value);
        setIsEndDateVisible(!isEndDateVisible);
    }
    const onClickSearch = () => {
        fetchData();
    }
    const handlePageChange = (value) => {
        setPage(value);
        console.log(value);
        fetchData()
    };
    const pageCount = Math.ceil(totalCount / pageSize);

    const fetchData = async () => {
        setIsLoading(true);
        let selectedFilter = '';
        if (selectedSearchFilter) {
            selectedFilter = selectedSearchFilter.value;
        } else {
            selectedFilter = 'lpg_dist_id';
        }
        let selectedisPaidorNot = [];
        let selectedLoanDisbursement = [];
        let selectedDelivery = [];
        if (isPaidorNot !== null && isPaidorNot.length > 0) {
            isPaidorNot.forEach(element => {
                selectedisPaidorNot.push(element.value);
            });
        } else {
            selectedisPaidorNot = ['true', 'false'];

        }
        if (loanDisbursementStatus !== null && loanDisbursementStatus.length > 0) {
            loanDisbursementStatus.forEach(element => {
                selectedLoanDisbursement.push(element.value);
            });
        } else {
            selectedLoanDisbursement = ['approved', 'pending', 'rejected'];
        }
        if (deliveryStatus !== null && deliveryStatus.length > 0) {
            deliveryStatus.forEach(element => {
                selectedDelivery.push(element.value);
            });
        } else {
            selectedDelivery = ['Yes', 'No', 'true', 'false'];
        }

        // console.log(searchValue);
        // console.log(selectedFilter);
        // console.log(selectedLoanDisbursement);
        // console.log(selectedDelivery);
        // console.log(selectedisPaidorNot);
        await axios({
            url:
                `${process.env.REACT_APP_API_LINK
                }/GreattrAllpayments?page=${page}&pageSize=${pageSize}
                &selectedOption=${selectedFilter}&searchedValue=${searchValue.toString()}
&selectedpaidstatus=${JSON.stringify(
                    selectedisPaidorNot.length > 0 ? selectedisPaidorNot : ["true", "No"]
                )}&startDate=${startDate
                    .toISOString()
                    .substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}
            `,

            method: 'GET',
            responseType: "json",
        }).then((response) => {
            if (response.status === 200) {
                setIsLoading(false);
                setTotalCount(response.data.totalCount);
                setdata(response.data.data)
                console.log(response.data.data);
                console.log(Object.keys(response.data.data[0]))
            } else {
                //500 recieved handle request server error
            }
            console.log(response);
        })
    }

    useEffect(() => {
        fetchData()
    }, [])
    const downloaddata = async (

    ) => {
        let selectedFilter = '';
        if (selectedSearchFilter) {
            selectedFilter = selectedSearchFilter.value;
        } else {
            selectedFilter = 'lpg_dist_id';
        }
        let selectedisPaidorNot = [];
        let selectedLoanDisbursement = [];
        let selectedDelivery = [];
        if (isPaidorNot !== null && isPaidorNot.length > 0) {
            isPaidorNot.forEach(element => {
                selectedisPaidorNot.push(element.value);
            });
        } else {
            selectedisPaidorNot = ['true', 'false'];

        }
        if (loanDisbursementStatus !== null && loanDisbursementStatus.length > 0) {
            loanDisbursementStatus.forEach(element => {
                selectedLoanDisbursement.push(element.value);
            });
        } else {
            selectedLoanDisbursement = ['approved', 'pending', 'rejected'];
        }
        if (deliveryStatus !== null && deliveryStatus.length > 0) {
            deliveryStatus.forEach(element => {
                selectedDelivery.push(element.value);
            });
        } else {
            selectedDelivery = ['Yes', 'No', 'true', 'false'];
        }
        var url = `${process.env.REACT_APP_API_LINK
            }/downloadGreattrAllCustomers?page=${page}&pageSize=${pageSize}&selectedOption=${selectedFilter}&searchedValue=${searchValue.length === 0 ? 'All' : searchValue}&selectedpaidstatus=${JSON.stringify(
                selectedisPaidorNot
            )}&startDate=${startDate
                .toISOString()
                .substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}
            `;
        window.open(url, "_blank");
    };
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }
    const getPageItems = () => {
        const items = [];

        // Add Prev button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => {
                    if (page > 1) {
                        handlePageChange(page - 1);
                    }
                }}
                disabled={page <= 1}
            />
        );

        // Add page numbers
        for (let i = Math.max(1, page - 4); i <= Math.min(pageCount, page + 5); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={page === i}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add Next button
        items.push(
            <Pagination.Next
                key="next"
                onClick={() => {
                    if (page < pageCount) {
                        handlePageChange(page + 1);
                    }
                }}
                disabled={page >= pageCount}
            />
        );

        return items;
    };


    return (
        <Container>
            <div className="d-flex justify-content-between"
                style={{ height: 100 }}
            >
                <h1 className='align-self-end'>EMIs Overdue</h1>
                {/* <img src={user_icon} alt="" className='m-4' height={44} width={44} /> */}
            </div>
            <div className='d-flex flex-wrap'>
                <div className="input-group m-1" style={{ maxWidth: 600 }}>
                    <img src={search_icon} alt="" height={44} width={44} className="input-group-text bg-light  border light" id="basic-addon1" />
                    <input type="text" className="form-control border light" value={searchValue} onChange={v => setSearchValue(v.target.value)} placeholder="Search" aria-label="search" aria-describedby="basic-addon1" />
                </div>

                <div style={{ minWidth: 170 }} className='m-1' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                                boxShadow: 0,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Search Filter"}
                        options={ccList}
                        hideSelectedOptions={false}
                        isClearable={true}
                        onChange={setSelectedSearchFilter}
                        value={selectedSearchFilter}
                    />
                </div>
                {/* <Button variant="light border m-1" onClick={onClickSearch}>Search</Button> */}
                <img src={download_icon} alt="" height={44} width={44}
                    className="input-group-text bg-light border light m-1 my-button" id="basic-addon1"
                    onClick={() => downloaddata()}
                />
                <img src={refresh_icon} alt="" height={44} width={44}
                    className="input-group-text bg-light border light m-1 my-button" id="basic-addon1"
                    onClick={() => fetchData()} />
            </div>
            <div className='d-flex flex-wrap  justify-content-between'>
                <div style={{ minWidth: 300 }} className='my-2 mx-1' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                                boxShadow: 0,
                            }),
                            option: (base, state) => ({
                                ...base,
                                color: state.isSelected ? '#573A87' : 'inherit',
                                backgroundColor: 'white'
                            })
                        }}
                        placeholder={"Paid or not"}
                        options={PaidorNot}
                        isMulti={true}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setisPaidorNot(v) }}
                        value={isPaidorNot}
                    />
                </div>

                <Button variant="primary fw-bold mx-1 my-2 " onClick={onClickSearch}>Search</Button>
            </div>

            <div className='row col-lg-6'>
                <div className='col-lg-6 col-sm-12 my-1 ' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='mx-1' style={{ display: "flex", alignItems: 'center' }}>
                        <p className='m-0'>Start Date: </p>
                        <Button variant='light border-primary mx-1' onClick={() => { setIsStartDateVisible(!isStartDateVisible) }}>{startDate.toLocaleDateString('en-UK', options).substring(0, 10)}</Button>
                    </div>
                    {isStartDateVisible ?
                        <Calendar onChange={onStartDateChange} className="m-2" value={startDate} />
                        : null}
                </div>
                <div className='col-lg-6 col-sm-12 my-1 ' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='mx-1' style={{ display: "flex", alignItems: 'center' }}>
                        <p className='m-0'>End Date: </p>
                        <Button variant='light border-primary mx-1' onClick={() => { setIsEndDateVisible(!isEndDateVisible) }}>{endDate.toLocaleDateString('en-UK', options).substring(0, 10)}</Button>
                    </div>
                    {isEndDateVisible ?
                        <Calendar onChange={onEndDateChange} className="m-2" value={endDate} />
                        : null}
                </div>



            </div>
            {columns && data != null && !isLoading ?
                <Table className='mt-3' hover style={{ textAlign: 'center', alignItems: 'center' }} responsive>
                    <thead>
                        <tr style={{ color: '#809FB8', height: 50, verticalAlign: "middle" }}>
                            {columns.map((column) => <td key={column} onClick={() => {
                                // alert(column);
                                handleSort(column)
                            }}>{column}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => {
                            if (!sortField) return 0;
                            const aValue = a[sortField];
                            const bValue = b[sortField];

                            if (aValue === undefined || bValue === undefined) return 0;
                            const comparison = aValue.localeCompare(bValue);
                            return sortOrder === 'asc' ? comparison : -comparison;
                        }).map((item) => (
                            <tr key={item.id}>
                                {/* <td className='tdx'>{(item.id)}</td> */}
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>
                                    {item.lpg_id.toString().padStart(17, '0')}</td>
                                {/* <td style={{ color: '#06152B', fontWeight: '400',
                                 fontSize: '0.81rem', 
                                 }}>{item.disbursed ? 'Yes' : 'No'}</td> */}
                                <td >

                                    <TableStatus text={item.disbursed} />
                                </td>

                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{item.lpg_mobile}</td>
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{item.name}</td>
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{item.lpg_dist_id}</td>
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{item.lpg_dist_name}</td>
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{new Date(item.dueDate).toLocaleDateString('en-UK', options).substring(0, 10)}</td>
                                <td style={{ color: '#06152B', fontWeight: '400', fontSize: '0.81rem', }}>{item.helperid ? item.helperid : "NA"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table> :

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Spinner animation="border" /></div>
            }
            <div>
                {/* Math.ceil(totalCount / pageSize) */}
                <Pagination style={{ float: 'right' }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last onClick={() => handlePageChange(pageCount)} />
                </Pagination>
            </div>
        </Container>
    )
}
